import React from "react"
import { useInView } from "react-intersection-observer"

const useIsCloseEnoughToLoad = () => {
  const { ref, inView /* , entry: _entry */ } = useInView({
    // Number between 0 and 1 indicating the percentage that should be visible
    // before triggering. Can also be an array of numbers, to create multiple
    // trigger points.
    threshold: .1,
  });

  return { containerEl: ref, isCloseEnoughToLoad: inView }
}

const useCanFadeIn = ({ isSourceLoaded }) => {
  const [canFadeIn, setCanFadeIn] = React.useState(false)

  React.useEffect(
    function handleOneTimeSet() {
      if (isSourceLoaded) {
        const handle = setTimeout(() => {
          setCanFadeIn(true)
        }, 100)

        return () => {
          clearTimeout(handle)
        }
      }
    },
    [isSourceLoaded]
  )

  return { canFadeIn }
}

const AsyncImage = ({ fallback, portfolioGif, alt }) => {
  // Props.
  if (!fallback) throw new Error("Missing fallback.")
  if (!alt) throw new Error("Missing alt.")
  if (!portfolioGif) throw new Error("Missing portfolioGif.")
  const source = `/${portfolioGif}.gif`

  // Check whether element is close enough to load.
  const { containerEl, isCloseEnoughToLoad } = useIsCloseEnoughToLoad()

  // Check whether source has been loaded.
  const [loadedSource, setLoadedSource] = React.useState("")
  const isSourceLoaded = loadedSource === source

  // Check whether we can fade the element in.
  const { canFadeIn } = useCanFadeIn({ isSourceLoaded })

  React.useEffect(
    function handleLoad() {
      if (!isCloseEnoughToLoad || isSourceLoaded) {
        return
      }

      const image = new Image()

      const handleLoad = () => {
        setLoadedSource(source)
      }

      const handleError = () => {
        setLoadedSource(new Error(`Failed to load: ${source}`))
      }

      image.addEventListener("load", handleLoad)
      image.addEventListener("error", handleError)
      image.src = source

      return () => {
        image.removeEventListener("load", handleLoad)
        image.removeEventListener("error", handleError)
      }
    },
    [isCloseEnoughToLoad, isSourceLoaded, source]
  )

  return (
    <div
      ref={containerEl}
      style={{
        display: "flex",
        position: "relative",
        minHeight: 149, // Shortest mobile GIF height as of this writing.
      }}
    >
      {isSourceLoaded ? (
        <img
          className={canFadeIn ? "AsyncImage AsyncImageLoaded" : "AsyncImage"}
          src={source}
          alt={alt}
        />
      ) : (
        <div
          style={{
            position: "absolute",
            height: "100%",
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            fontStyle: "italic",
            color: "black",
          }}
        >
          <div class="lds-hourglass" />
          <div>Loading GIF...</div>
        </div>
      )}
    </div>
  )
}

const AsyncVideo = ({ portfolioMp4, alt }) => {
  // Props.
  if (!alt) throw new Error("Missing alt.")
  if (!portfolioMp4) throw new Error("Missing portfolioMp4.")

  // Check whether container is close enough to load.
  const { containerEl, isCloseEnoughToLoad } = useIsCloseEnoughToLoad()

  // The source string to load.
  const [source, setSource] = React.useState(null)

  // Check whether source has been loaded.
  const [loadedSource, setLoadedSource] = React.useState("")
  const isSourceLoaded = loadedSource === source
  const { canFadeIn } = useCanFadeIn({ isSourceLoaded })

  // Video element.
  const videoEl = React.useRef()
  const videoElCurrent = videoEl ? videoEl.current : null

  React.useEffect(
    function handleLoad() {
      if (!isCloseEnoughToLoad || !videoEl || !videoEl.current) {
        return
      }

      const sourceToLoad = `/${portfolioMp4}.mp4`

      const handleLoad = () => {
        setLoadedSource(sourceToLoad)
      }

      const handleError = () => {
        setLoadedSource(new Error(`Failed to load: ${sourceToLoad}`))
      }

      videoElCurrent.addEventListener("canplaythrough", handleLoad)
      videoElCurrent.addEventListener("error", handleError)
      setSource(sourceToLoad)
      videoElCurrent.load()

      return () => {
        if (videoElCurrent) {
          videoElCurrent.removeEventListener("load", handleLoad)
          videoElCurrent.removeEventListener("error", handleError)
        }
      }
    },
    [isCloseEnoughToLoad, videoEl, videoElCurrent, portfolioMp4]
  )

  return (
    <div
      ref={containerEl}
      style={{
        display: "flex",
        position: "relative",
        minHeight: 149, // Shortest mobile GIF height as of this writing.
      }}
    >
      {!isSourceLoaded ? (
        <div
          style={{
            position: "absolute",
            height: "100%",
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            fontStyle: "italic",
            color: "black",
          }}
        >
          <div class="lds-hourglass" />
          <div>Loading GIF...</div>
        </div>
      ) : null}
      <video
        ref={videoEl}
        autoPlay
        loop
        muted
        playsInline
        width="100%"
        className={canFadeIn ? "AsyncImage AsyncImageLoaded" : "AsyncImage"}
      >
        <source src={source} type="video/mp4" />
        <p>{alt}</p>
      </video>
    </div>
  )
}

export { AsyncImage, AsyncVideo }
