import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

// <JasonLarge /> is a picture of me, for use on the portfolio homepage.
const JasonLarge = ({ style }) => {
  const data = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "jason.jpg" }) {
        childImageSharp {
          fixed(width: 200, height: 200) {
            ...GatsbyImageSharpFixed_withWebp_noBase64
          }
        }
      }
    }
  `)

  return (
    <Img
      className="JasonLarge"
      fixed={data.file.childImageSharp.fixed}
      style={{ borderRadius: "50%", ...style }}
    />
  )
}

export { JasonLarge }
