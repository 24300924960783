import ColorHash from "color-hash"
import { Link } from "gatsby"
import Img from "gatsby-image"
import React from "react"
import { AsyncImage, AsyncVideo } from "./AsyncImage"
import { useMediaQuery } from "react-responsive"
import { DiabeticRed } from "../utils/colors"
import theme from "../utils/theme"
import { rhythm, scale } from "../utils/typography"
import YouTubeVideo from "./YouTube"

const BodyFontFamily = theme.bodyFontFamily
  .map((font, i) =>
    i !== theme.bodyFontFamily.length - 1 ? `'${font}'` : font
  )
  .join(", ")

const TagLink = props => {
  const [isHovering, setHovering] = React.useState(false)

  const styles = {
    fontStyle: "italic",
    backgroundColor: isHovering
      ? new ColorHash().hex(props.tags)
      : new ColorHash({ lightness: 0.9, saturation: 0.5 }).hex(props.tags),
    color: isHovering ? "white" : "black",
    borderRadius: 16,
    paddingLeft: 12,
    paddingRight: 12,
    marginBottom: rhythm(1 / 2),
    display: "inline-block",
  }

  if (props.noLink) {
    return <span style={styles}>{props.tags}</span>
  }

  return (
    <Link
      style={styles}
      to={"/" + props.tags}
      onMouseEnter={() => setHovering(true)}
      onMouseLeave={() => setHovering(false)}
    >
      {props.tags}
    </Link>
  )
}

const Card = props => {
  // Preconditions.
  const Required = ["slug", "title", "subtitle", "description", "tags"]
  for (const field of Required) {
    if (!(field in props)) {
      throw new Error(`Missing "${field}" in props.`)
    }
  }

  // Computed props.
  const shouldShowTagLinks = !props.hideTagLinks
  const readMore = props.customReadMore || "Read more"

  // React hooks.
  const isMobile = useMediaQuery({ maxWidth: 767 })

  // Custom render function.
  const CustomizableLink = additionalProps => {
    if (props.customReadMoreUrl) {
      return (
        /* eslint-disable jsx-a11y/anchor-has-content */
        <a
          href={props.customReadMoreUrl}
          {...additionalProps}
          target="_blank"
          rel="noreferrer"
        />
        /* eslint-enable jsx-a11y/anchor-has-content */
      )
    } else {
      return <Link to={props.slug} {...additionalProps} />
    }
  }

  return (
    <CustomizableLink
      className="Card"
      style={{
        padding: 0,
        display: "block",
        borderWidth: 1,
        borderStyle: "solid",
      }}
    >
      {/* YouTube preview. Default ID to try: 3z_4P3B8EPE */}
      {props.youtubeId && <YouTubeVideo embedId={props.youtubeId} />}{" "}
      {/* GIF image. */}
      {!props.youTubeId && props.previewImage && props.portfolioGif ? (
        props.isMovie ? (
          <AsyncVideo
            key={props.portfolioGif}
            portfolioMp4={props.portfolioGif}
            alt={props.title}
          />
        ) : (
          <AsyncImage
            key={props.portfolioGif}
            portfolioGif={props.portfolioGif}
            alt={props.title}
            fallback={<Img fluid={props.previewImage} />}
          />
        )
      ) : null}
      {/* Static image. */}
      {!props.youtubeId && props.previewImage && !props.portfolioGif && (
        <Img fluid={props.previewImage} />
      )}
      <div
        style={{
          paddingLeft: isMobile ? rhythm(1 / 2) : rhythm(1),
          paddingRight: isMobile ? rhythm(1 / 2) : rhythm(1),
          paddingTop: rhythm(1),
          paddingBottom: rhythm(1),
        }}
      >
        {shouldShowTagLinks && (
          <>
            <TagLink noLink tags={props.tags} />
            &nbsp;
          </>
        )}
        {props.isPopular && (
          <>
            <TagLink noLink tags={'Popular'} />
            &nbsp;
          </>
        )}
        <h2
          style={{
            color: DiabeticRed,
            marginTop: 0,
            marginBottom: rhythm(1 / 2),
            fontFamily: BodyFontFamily,
          }}
          className="ClickableCardHeading"
        >
          {props.title}
        </h2>
        {!shouldShowTagLinks && props.projectType && (
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignContent: "center",
            }}
          >
            <div>
              <strong style={{ color: "hsla(0, 0%, 0%, 0.8)" }}>
                Project Type
              </strong>
            </div>
            <div>
              <TagLink tags={props.projectType} noLink />
            </div>
          </div>
        )}
        {!shouldShowTagLinks && props.teamSize && (
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignContent: "center",
            }}
          >
            <div>
              <strong style={{ color: "hsla(0, 0%, 0%, 0.8)" }}>
                Team Size
              </strong>
            </div>
            <div>
              <TagLink tags={props.teamSize} noLink />
            </div>
          </div>
        )}
        {!shouldShowTagLinks && props.platform && (
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignContent: "center",
            }}
          >
            <div>
              <strong style={{ color: "hsla(0, 0%, 0%, 0.8)" }}>
                Platform
              </strong>
            </div>
            <div>
              <TagLink tags={props.platform} noLink />
            </div>
          </div>
        )}
        {!shouldShowTagLinks && props.gameEngine && (
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignContent: "center",
            }}
          >
            <div>
              <strong style={{ color: "hsla(0, 0%, 0%, 0.8)" }}>Tech</strong>
            </div>
            <div>
              <TagLink tags={props.gameEngine} noLink />
            </div>
          </div>
        )}
        {props.portfolioLine1 || props.portfolioLine2 ? (
          <>
            <p
              style={{
                fontStyle: "italic",
                color: "hsla(0, 0%, 0%, 0.8)",
                fontWeight: 300,
                fontFamily: BodyFontFamily,
                marginLeft: 0,
                marginRight: 0,
                marginTop: 0,
                marginBottom: rhythm(1 / 2),
                ...scale(1 / 4),
              }}
            >
              {props.portfolioLine1}
            </p>
            <p
              style={{
                fontStyle: "italic",
                color: "hsla(0, 0%, 0%, 0.8)",
                fontWeight: 300,
                fontFamily: BodyFontFamily,
                marginLeft: 0,
                marginRight: 0,
                marginTop: 0,
                marginBottom: rhythm(1),
                ...scale(1 / 4),
              }}
            >
              {props.portfolioLine2}
            </p>
          </>
        ) : (
          <p
            style={{
              fontStyle: "italic",
              color: "hsla(0, 0%, 0%, 0.8)",
              fontWeight: 300,
              fontFamily: BodyFontFamily,
              marginLeft: 0,
              marginRight: 0,
              marginTop: 0,
              marginBottom: rhythm(1),
              ...scale(1 / 4),
            }}
          >
            {props.subtitle}
          </p>
        )}
        {false && (
          <p style={{ color: "black", marginTop: 0, marginBottom: rhythm(1) }}>
            {props.description}
          </p>
        )}
        <div className="CallToAction" style={{ color: DiabeticRed }}>
          {readMore} →
        </div>
      </div>
    </CustomizableLink>
  )
}

export { Card as ClickableCard }
