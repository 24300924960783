import { Link, graphql } from "gatsby"
import React from "react"
import { useMediaQuery } from "react-responsive"
import { ClickableCard } from "../components/ClickableCard"
import { Footer } from "../components/Footer"
import { FreelanceHeader2 } from "../components/Header"
import { SEO } from "../components/Seo"
import { DiabeticRed } from "../utils/colors"
import { BodyFontFamily } from "../utils/theme"
import { rhythm, scale } from "../utils/typography"
import tocbot from "tocbot"
import "tocbot/dist/tocbot.css"
import { JasonLarge } from "../components/JasonLarge"

const CardList = ({ blogPostNodes }) => {
  const isMobile = useMediaQuery({ maxWidth: 767 })
  return (
    <ul
      style={{
        listStyle: "none",
        margin: 0,
      }}
    >
      {blogPostNodes.map(node => (
        <li
          style={{
            marginBottom: isMobile ? rhythm(1 / 2) : rhythm(1),
          }}
          key={node.id}
        >
          <h2
            id={node.fields.slug}
            style={{
              visibility: "hidden",
              height: 0,
              margin: 0,
              padding: 0,
            }}
          >
            {node.frontmatter.portfolioTitle}
          </h2>
          <ClickableCard
            title={node.frontmatter.portfolioTitle}
            subtitle={node.frontmatter.portfolioSubtitle}
            slug={node.fields.slug}
            emojiClassName={node.frontmatter.emojiClassName}
            description={node.frontmatter.description}
            previewImage={
              node.frontmatter.previewImageUrl &&
              node.frontmatter.previewImageUrl.childImageSharp
                ? node.frontmatter.previewImageUrl.childImageSharp.fluid
                : null
            }
            tags={node.frontmatter.tags}
            customReadMore={node.frontmatter.customReadMore}
            customReadMoreUrl={node.frontmatter.customReadMoreUrl}
            hideTagLinks
            projectType={node.frontmatter.projectType}
            teamSize={node.frontmatter.teamSize}
            platform={node.frontmatter.platform}
            gameEngine={node.frontmatter.gameEngine}
            youtubeId={node.frontmatter.youtubeId}
            portfolioLine1={node.frontmatter.portfolioLine1}
            portfolioLine2={node.frontmatter.portfolioLine2}
            portfolioGif={node.frontmatter.portfolioGif}
            isMovie={node.frontmatter.isMovie}
          />
        </li>
      ))}
    </ul>
  )
}

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        description
      }
    }

    allMdx(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { contentType: { eq: "new-portfolio" } } }
    ) {
      edges {
        node {
          id
          body
          frontmatter {
            date(formatString: "MMMM Do, YYYY")
            twitterImageUrl
            chapter
            readingTime
            title
            subtitle
            description
            previewImageUrl {
              childImageSharp {
                fluid(maxWidth: 1200) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
            portfolioGif
            tags
            portfolioTitle
            portfolioSubtitle
            customReadMore
            customReadMoreUrl
            projectType
            teamSize
            platform
            gameEngine
            portfolioOrder
            youtubeId
            portfolioLine1
            portfolioLine2
            isMovie
            isPopular
          }
          fields {
            slug
          }
        }
      }
    }

    gameMockup: file(relativePath: { eq: "mockup6_landing.png" }) {
      childImageSharp {
        fluid(maxWidth: 2000) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    freelanceBanner: file(relativePath: { eq: "homepage_thumbnail2.png" }) {
      childImageSharp {
        fluid(maxWidth: 2000) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    reelPlaceholder: file(relativePath: { eq: "reel_placeholder.png" }) {
      childImageSharp {
        fluid(maxWidth: 2000) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
  }
`

const LandingPage2 = ({ data }) => {
  const growthNodes = data.allMdx.edges
    .map(edge => edge.node)
    .filter(node => node.frontmatter.tags !== "do-not-show")
    .filter(
      node =>
        node.frontmatter.projectType !== "Unreal Engine Tutorial" &&
        node.frontmatter.projectType !== "Unity Tutorial" &&
        node.frontmatter.projectType !== "Game Design Analysis"
    )

  growthNodes.sort((a, b) => {
    if (a.frontmatter.portfolioOrder < b.frontmatter.portfolioOrder) return -1
    if (a.frontmatter.portfolioOrder > b.frontmatter.portfolioOrder) return 1
    return 0
  })

  /*
  const currentProjects = growthNodes.filter(
    node => node.frontmatter.tags === "wip"
  )
  */

  const pastProjects = growthNodes.filter(
    node => node.frontmatter.tags !== "wip"
  )

  const blogPostNodes = data.allMdx.edges
    .map(edge => edge.node)
    .filter(node => node.frontmatter.tags !== "do-not-show")
    .filter(
      node =>
        node.frontmatter.projectType === "Unreal Engine Tutorial" ||
        node.frontmatter.projectType === "Unity Tutorial" ||
        node.frontmatter.projectType === "Game Design Analysis"
    )

  blogPostNodes.sort((a, b) => {
    if (a.frontmatter.portfolioOrder < b.frontmatter.portfolioOrder) return -1
    if (a.frontmatter.portfolioOrder > b.frontmatter.portfolioOrder) return 1
    return 0
  })

  const isMobile = useMediaQuery({ maxWidth: 767 })
  const showTocbot = useMediaQuery({ minWidth: 1420 })

  React.useEffect(() => {
    if (showTocbot) {
      tocbot.init({
        // Where to render the table of contents.
        tocSelector: ".js-toc",

        // Where to grab the headings to build the table of contents.
        contentSelector: ".IndexPageContent",

        // Which headings to grab inside of the contentSelector element.
        headingSelector: "h1,h2",

        // For headings inside relative or absolute positioned containers within content.
        hasInnerContainers: true,

        ignoreSelector: ".ClickableCardHeading,.TocbotIgnore",
      })
    }
  }, [showTocbot])

  return (
    <>
      <SEO
        title="Portfolio"
        imageUrl={`https://jasont.co${data.freelanceBanner.childImageSharp.fluid.src}`}
        imageAlt="The gameplay engineering portfolio of Jason Tu."
        description="The gameplay engineering portfolio of Jason Tu."
      />
      <div
        style={{
          position: "fixed",
          right: 10,
          top: 155,
        }}
      >
        <div className="js-toc" />
      </div>
      <FreelanceHeader2 />
      <div className="IndexPageContent">
        <section
          style={{
            maxWidth: 600,
            margin: "0 auto",
            paddingLeft: rhythm(1 / 2),
            paddingRight: rhythm(1 / 2),
          }}
        >
          {/*
          <div
            style={{
              display: "flex",
              alignItems: "center",
              backgroundColor: "#FFF4BC",
              paddingLeft: rhythm(1),
              marginTop: rhythm(1),
              paddingRight: rhythm(1),
              paddingTop: rhythm(1),
              paddingBottom: rhythm(1),
              border: "solid 1px #ffda1f",
              borderRadius: 8,
            }}
          >
            <div style={{ marginRight: rhythm(1), fontSize: "2rem" }}>
              <span role="img" aria-label="warning">
                🚧
              </span>
            </div>
            <div>
              <p
                style={{
                  fontFamily: BodyFontFamily,
                  marginBottom: rhythm(1 / 2),
                  marginTop: 0,
                }}
              >
                <strong>Note</strong>: this portfolio is a work-in-progress!
              </p>
              <p style={{ marginBottom: 0 }}>
                I'm spending 2023 focused purely on creating games in Unreal
                Engine, and I will publish regular updates to my portfolio (this
                page) as my game projects progress.
              </p>
            </div>
          </div>
          */}
          <h1
            id="who-am-i"
            style={{ visibility: "hidden", height: 0, margin: 0, padding: 0 }}
          >
            Who am I?
          </h1>
          <h1
            style={{
              fontFamily: BodyFontFamily,
              lineHeight: rhythm(3 / 2),
            }}
            className="TocbotIgnore"
          >
            <JasonLarge />
            <div style={{ marginTop: rhythm(1) }}>
              Hi, I'm <span style={{ color: DiabeticRed }}>Jason Tu</span> 🤝
            </div>
          </h1>
          <h3
            style={{
              fontFamily: BodyFontFamily,
              fontWeight: 100,
              lineHeight: rhythm(5 / 4),
              marginTop: 0,
            }}
          >
            I'm a Gameplay Engineer with a specialty in UI/UX.
          </h3>
          <h3
            style={{
              fontFamily: BodyFontFamily,
              fontWeight: 100,
              lineHeight: rhythm(5 / 4),
              marginTop: 0,
            }}
          >
            I work in both Unreal Engine (C++, UMG/Slate, Blueprints), as well
            as Unity (C#, iOS, Android). And I take pride in crafting gameplay &
            UI with careful attention to detail.
          </h3>
          <h3
            style={{
              fontFamily: BodyFontFamily,
              fontWeight: 100,
              lineHeight: rhythm(5 / 4),
              marginTop: 0,
            }}
          >
            Previously, I worked as a Senior Full-Stack Engineer (10+ years) in
            various, successful tech startups.{" "}
            <a href="/Tu_Jason_resume_oct_29_2023.pdf" target="_blank">
              <strong>You can take a look at my resume here.</strong>{" "}
              <small>
                <em>(last updated Oct 29, 2023)</em>
              </small>
            </a>
          </h3>
          <h3
            style={{
              fontFamily: BodyFontFamily,
              fontWeight: 100,
              lineHeight: rhythm(5 / 4),
              marginTop: 0,
            }}
          >
            If your game studio is looking for a Gameplay or UI Programmer,
            let's chat.{" "}
            <strong>
              Get in touch: <a href="mailto:hello@jasont.co">hello@jasont.co</a>
            </strong>
          </h3>
          <h3
            style={{
              fontFamily: BodyFontFamily,
              fontWeight: 100,
              lineHeight: rhythm(5 / 4),
              marginTop: 0,
            }}
          >
            Otherwise, you can take a look at my games work below{" "}
            <span role="img" aria-label="point down">
              👇
            </span>
          </h3>
          <h1 style={{ ...scale(3 / 5) }} id="game-projects">
            Game Projects
          </h1>
          <CardList blogPostNodes={pastProjects} />
          {/*
          <h1 style={{ ...scale(3 / 5) }} id="work-in-progress">
            Work in Progress
          </h1>
          <p style={{ fontSize: "1.2rem" }}>
            <em>The work-in-progress project that I'm actively working on.</em>
          </p>
          <CardList blogPostNodes={currentProjects} />
          */}
          <h1
            style={{
              marginTop: rhythm(5 / 2),
              ...scale(3 / 5),
            }}
            id="writing-and-speaking"
          >
            Writing and Speaking
          </h1>
          <p style={{ fontSize: "1.2rem" }}>
            <em>
              A selection of my latest, or most popular game dev tutorials.
            </em>
          </p>
          <ul
            style={{
              listStyle: "none",
              margin: 0,
            }}
          >
            {blogPostNodes.map((node, i) => (
              <li
                style={{
                  marginBottom: isMobile ? rhythm(1 / 2) : rhythm(1),
                }}
                key={node.id}
              >
                <h2
                  id={node.fields.slug}
                  style={{
                    visibility: "hidden",
                    height: 0,
                    margin: 0,
                    padding: 0,
                  }}
                >
                  {node.frontmatter.portfolioTitle}
                </h2>
                <ClickableCard
                  title={node.frontmatter.portfolioTitle}
                  subtitle={node.frontmatter.portfolioSubtitle}
                  portfolioLine1={node.frontmatter.portfolioLine1}
                  portfolioLine2={node.frontmatter.portfolioLine2}
                  slug={node.fields.slug}
                  emojiClassName={node.frontmatter.emojiClassName}
                  description={node.frontmatter.description}
                  tags={node.frontmatter.projectType}
                  customReadMore={node.frontmatter.customReadMore}
                  customReadMoreUrl={node.frontmatter.customReadMoreUrl}
                  isPopular={node.frontmatter.isPopular}
                />
              </li>
            ))}
          </ul>
          <Link to="/blog" style={{ fontSize: "1.2rem" }}>
            <em>View all blog posts</em> →
          </Link>
        </section>
        <div style={{ marginTop: rhythm(3 / 2) }}>
          <Footer />
        </div>
      </div>
    </>
  )
}

export default LandingPage2
